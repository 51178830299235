import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"

const Jobs = () => {

    const data = useStaticQuery(
        graphql`
        query {
          allMdx(filter: {frontmatter: {slug: {eq: "jobs"}}}) {
            edges {
              node {
                frontmatter {
                  pretitle
                  title
                  image {
                    relativePath
                  }
                  slug
                }
                body
              }
            }
          }
          site {
            siteMetadata {
              contacts {
                mail
              }
            }
          }
        }
        `
      )

    const post = data.allMdx.edges[0].node;
    const mailLink = 'mailto:'+data.site.siteMetadata.contacts.mail; {/* https://ctrlq.org/encode/ */}

    return (
        <section className="section mx-6">
          <a className="anchor" id="jobs"></a>
            <div className="columns">


            <div className="column has-text-right">
                    <h6 className="subtitle is-6 is-uppercase is-italic">{post.frontmatter.pretitle}</h6>
                    <h3 className="title is-3 is-uppercase has-text-weight-bold is-italic">{post.frontmatter.title}</h3>
                    <div className="content">
                        <MDXRenderer>{post.body}</MDXRenderer>
                    </div> 
                      
                      <a class="button is-medium is-italic is-uppercase has-text-weight-bold" href={mailLink}>Contattaci</a>
                </div>

                <div className="column">
                    <figure className="image is-1by1 pt-0 px-0">
                        <StaticImage 
                            src="../images/contract.png" 
                            alt="contract"
                            placeholder="blurred"
                            width={548}
                            height={548} 
                        />
                    </figure>
                </div>
                
            </div>
        </section>  )
}

export default Jobs
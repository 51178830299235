import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faPhone, faEnvelopeOpen} from '@fortawesome/free-solid-svg-icons'

const Contacts = () => {

    const data = useStaticQuery(
        graphql`
        query {
            site {
              siteMetadata {
                title
                contacts {
                  address
                  cap
                  city
                  fax
                  mail
                  phone
                  state
                }
              }
            }
          }
        `)

    return (
        <section className="section has-background-white-ter">
            <a className="anchor" id="contacts"></a>
            <div className="has-text-centered mb-5">
              <h6 className="subtitle is-6 is-uppercase is-italic">Come raggiungerci</h6>
              <h3 className="title is-3 is-uppercase has-text-weight-bold is-italic">Contatti</h3>
            </div>

            <div className="columns mt-5">

                <div className="column content pl-6 pt-6">

                    <h3 className="is-3 has-text-weight-bold is-italic is-uppercase mb-4">{data.site.siteMetadata.title}</h3>
                    <div className="icon-text">
                            <span className="icon">
                                <FontAwesomeIcon icon={faMapMarker} />
                            </span>
                            <span>{data.site.siteMetadata.contacts.address}</span>
                            
                    </div>
                    <p className="block ml-5">
                        {data.site.siteMetadata.contacts.cap}
                        <br></br>
                        {data.site.siteMetadata.contacts.city}
                        <br></br>
                        {data.site.siteMetadata.contacts.state}
                    </p>

                    <div className="icon-text mb-5">
                        <span className="icon">
                            <FontAwesomeIcon icon={faPhone} />
                        </span>
                        <span>{data.site.siteMetadata.contacts.phone}</span>
                        
                    </div>

                    <div className="icon-text">
                        <span className="icon">
                            <FontAwesomeIcon icon={faEnvelopeOpen} />
                        </span>
                        <span>{data.site.siteMetadata.contacts.mail}</span>
                        
                    </div>

                </div>

                <div className="column content is-two-thirds">

                <div className="map-responsive">
                    <iframe width="100%" height="400" 
                    id="gmap_canvas" 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2871.549240581951!2d12.461365315928509!3d43.96868997911172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132ceb17a9390ff3%3A0xa7e76fb27e1ce5c1!2sStr.%20La%20Ciarulla%2C%2094%2C%2047899!5e0!3m2!1sit!2ssm!4v1626423350441!5m2!1sit!2ssm"
                    frameBorder="0" 
                    scrolling="no" 
                    marginHeight="0" marginWidth="0"></iframe>
                </div>

                </div>

            </div>
            
          </section>)
}

export default Contacts
import * as React from "react"
import Layout from "../components/layout"
import TopImage from "../components/topImage"
import About from "../components/about"
import Stats from "../components/stats"
import Jobs from "../components/jobs"
import Contacts from "../components/contacts"


//export default IndexPage
export default function Home() {

  return (
    <Layout>
      <TopImage></TopImage>
      <About></About>
      <Stats></Stats>
      <Jobs></Jobs>
      <Contacts></Contacts>
    </Layout>
  )
}
import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"

const Stats = () => {

    const data = useStaticQuery(
        graphql`
        query {
          allMdx(
            filter: {frontmatter: {slug: {eq: "stats"}}}
            sort: {fields: fileAbsolutePath}
          ) {
            edges {
              node {
                frontmatter {
                  pretitle
                  title
                  image {
                    relativePath
                  }
                  slug
                }
                fileAbsolutePath
                body
              }
            }
          }
        }
        `
      )

      const main = data.allMdx.edges[0].node;
      const col1 = data.allMdx.edges[1].node;
      const col2 = data.allMdx.edges[2].node;
      const col3 = data.allMdx.edges[3].node;


    return (
        <section id="stats" className="section px-6 has-background-white-ter">
          <a className="anchor" id="stats"></a>
            <div className="has-text-centered">
              <h6 className="subtitle is-6 is-uppercase is-italic">{main.frontmatter.pretitle}</h6>
              <h3 className="title is-3 is-uppercase has-text-weight-bold is-italic">{main.frontmatter.title}</h3>
              
            </div>
            
            <div> 
                <div className="columns mt-6">
                  <div className="column has-text-centered mb-6">
                        <figure className="image is-1by1 pt-0 px-0">
                          <StaticImage 
                              src="../images/clock.png" 
                              alt="contract"
                              placeholder="blurred"
                              width={100}
                              height={100} 
                          />
                        </figure>
                      <h2 className="subtitle is-6 is_spaced my-2 is-italic is-uppercase">{col1.frontmatter.title}</h2> 
                      <div className="content px-3">
                        <MDXRenderer>{col1.body}</MDXRenderer>
                      </div>
                  </div>

                  <div className="column has-text-centered mb-6">
                        <figure className="image is-1by1 pt-0 px-0">
                          <StaticImage 
                              src="../images/notes.png" 
                              alt="contract"
                              placeholder="blurred"
                              width={80}
                              height={100} 
                          />
                        </figure>
                      <h2 className="subtitle is-6 is_spaced my-2 is-italic is-uppercase">{col2.frontmatter.title}</h2> 
                      <div className="content px-3">
                        <MDXRenderer>{col2.body}</MDXRenderer>
                      </div>
                  </div>

                  <div className="column has-text-centered mb-6">
                        <figure className="image is-1by1 pt-0 px-0">
                          <StaticImage 
                              src="../images/box.png" 
                              alt="contract"
                              placeholder="blurred"
                              width={100}
                              height={100} 
                          />
                        </figure>
                      <h2 className="subtitle is-6 is_spaced my-2 is-italic is-uppercase">{col3.frontmatter.title}</h2> 
                      <div className="content px-3">
                        <MDXRenderer>{col3.body}</MDXRenderer>
                      </div>
                  </div>

                </div>
            </div>

          </section>  )
}

export default Stats
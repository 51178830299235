import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const TopImage = () => {
 return(
<section className="section mt-6 pb-0 px-0">
    <div className="hero is-large">
        <div className="hero-body has-text-centered">
            <h2 className="title is-1 has-text-white is-italic has-text-weight-bold is-uppercase">Trasporto per conto terzi sul territorio nazionale</h2>
        </div>
        
    </div>
    
</section>
   
 ) 
}

export default TopImage
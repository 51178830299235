import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"

const About = () => {

    const data = useStaticQuery(
        graphql`
        query {
            allMdx(filter: {frontmatter: {slug: {eq: "about"}}}) {
              edges {
                node {
                  frontmatter {
                    pretitle
                    title
                    image {
                      relativePath
                    }
                    slug
                  }
                  body
                }
              }
            }
          }
        `
      )

    const post = data.allMdx.edges[0].node;

    return (
        <section  className="section mx-6">
            <a className="anchor" id="about"></a>
            <div className="columns">
                <div className="column">
                    <figure className="image is-1by1 pt-0 px-0">
                        <StaticImage 
                            src="../images/man_small.png" 
                            alt="truck"
                            placeholder="blurred"
                            width={406}
                            height={400} 
                        />
                    </figure>
                </div>
                <div className="column">
                    <h6 className="subtitle is-6 is-uppercase is-italic">{post.frontmatter.pretitle}</h6>
                    <h3 className="title is-3 is-uppercase has-text-weight-bold is-italic">{post.frontmatter.title}</h3>
                    <div className="content">
                        <MDXRenderer>{post.body}</MDXRenderer>
                    </div>
                </div>
            </div>
        </section>  )
}

export default About